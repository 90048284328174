<template>
    <Head title="Clients - VIP Fares"></Head>
    <AppMainLayout title="Clients">
        <template #content>
            <div class="page-clients block block-rounded">
                <div class="block-content block-content-full">
                    <acms-v-table
                        class=""
                        v-bind="CLIENTS_TABLE_INDEX"
                        :data="collection.data"
                        :links="collection.meta.links"
                        v-model:page="tableForm.page"
                        sortKeys
                        @update:sort="tableSortHandle"
                        emptyStateTitle="Clients"
                        :entriesLimit="collection.meta.per_page"
                        :currentPage="collection.meta.current_page"
                        :search="tableForm.search"
                        :addHref="getRoute('clients.create')"
                        addTitle="Add client"
                        search-placeholder="Search request"
                        @update:selectedRows="updateSelectedRows"
                    >
                        <template #header>
                             <AcmsVTableHeader
                                :config="tableHeadConfig"
                                v-model:search="tableForm.search"
                                v-model:entries="tableForm.limit"
                                addTitle="Add client"
                                :addHref="getRoute('clients.create')"
                                search-placeholder="Search request"
                                @assign-agent="handleAssignAgent"
                                @delete-request="handleDeleteClient"
                                :agents="agents"
                                delete-title="clients"
                                :show-delete-button="backendPermissions.allowDeleteActiveClients || backendPermissions.allowDeleteUnassignedClients"
                                :hide-assign-agent-btn="!backendPermissions.allowAssignAgents"
                            />
                        </template>
                        <template #bodyCell-id="{item}">
                            {{ item.id }}
                        </template>
                        <template #bodyCell-first-name="{item}">
                            <Link :href="getRoute('clients.edit', item.id)" class="text-primary fw-semibold text-capitalize">
                                {{ getFullName(item) }}
                            </Link>
                        </template>
                        <template #bodyCell-email="{item}">
                            <div class="text-end" v-tooltip="{ list: getHiddenEmail(item.email, true) }">
                                {{ getHiddenEmail(item.email) }}
                            </div>
                        </template>
                        <template #bodyCell-phone="{item}">
                            <div class="text-end" v-tooltip="{ list: getHiddenPhone(item.phone, true) }">
                                {{ getHiddenPhone(item.phone) }}
                            </div>
                        </template>
                        <template #bodyCell-requests="{item}">
                            <div class="text-center">{{ item.requests }}</div>
                        </template>
                        <template #bodyCell-agent-status="{item}">
<!--                            <acms-v-badge class="text-end">-->
        <span v-for="(agent, index) in item.agents" :key="agent.agent_id">
            {{ agent.first_name }}<span v-if="index < item.agents.length - 1">, </span>
        </span>
<!--                            </acms-v-badge>-->
                        </template>
                    </acms-v-table>
                </div>
            </div>
        </template>
    </AppMainLayout>
</template>

<script>
import axios from 'axios';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

export default {
    name: 'ClientsPage',
    data() {
        return {
            selectedRows: [],
        };
    },
    methods: {
        updateSelectedRows(newSelectedRows) {
            this.selectedRows = newSelectedRows;
        },
        handleAssignAgent(data) {
            const clientIds = this.selectedRows.map(row => row.id);

            axios.post('clients/assign-agent', {
                agent_ids: data.agent, // Массив агент IDs
                client_ids: clientIds
            })
                .then(response => {
                    console.log('Success:', response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add( 'Agent successfully assigned to Clients');
                    this.$emit('agent-assigned', response.data.clients); // Добавьте это, если хотите обновить данные клиентов в вашем компоненте
                    // Вы можете добавить уведомление об успешном завершении операции здесь
                })
                .catch(error => {
                    console.error('Error:', error);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Please Select Clients and/or Agents', 'danger')
                });
        },
        handleDeleteClient() {
            const clientIds = this.selectedRows.map(row => row.id);

            console.log('Selected Request IDs:', clientIds);
            axios.post('clients/delete', {
                ids: clientIds
            })
                .then(response => {
                    console.log('Success:', response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Requests successfully deleted!');
                    this.$emit('agent-assigned', response.data.requests);
                    // window.location.reload();
                })
                .catch(error => {
                    console.error('Error:', error.response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add(error.response.data.message ??'Please Select Requests', 'danger');
                });
        }
    }
};
</script>

<script setup>
import { computed } from 'vue';
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';
import { Head, Link } from '@inertiajs/vue3';
import AppMainLayout from "@layouts/AppMainLayout.vue";
import { CLIENTS_TABLE_INDEX } from "@pages/clients/constants/tablesAndTabsSettings";
import { getFullName, getHiddenEmail, getHiddenPhone } from "@helpers/commonHelpers";
import { getRoute } from "@plugins/useRoutes";
import useTableSortable from "@ui/Table/useTableSortable";
import { useBackendMiddlewarePropsService } from "@services";

const props = defineProps({
    collection: Object,
    tableParams: Object,
    agents: Array
});
const { backendPermissions } = useBackendMiddlewarePropsService();
const { tableForm, tableSortHandle } = useTableSortable({
    route: '/clients',
    sendData: {
        limit: props.tableParams?.limit ?? 20,
        search: props.tableParams?.search ?? '',
    }
});

const tableHeadConfig = computed(() => {
    let config = 'search,entries';
    if (backendPermissions?.allowCreateClient) {
        config += ',add';
    }
    return config;
});
</script>

<style scoped>
</style>
